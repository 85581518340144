import { getStrapiURL } from "lib/api";
import Translate from "locale/Translate";
import { useGlobalContext } from "pages/_app";
import { memo } from "react";

const About = ({ footer }) => {
  const { logoHeader } = useGlobalContext();
  // const images = [
  //   "/images/partner/iesf.png",
  //   "/images/partner/gef3.png",
  //   "/images/partner/aesf.png",
  //   "/images/partner/SEAEF.jpg",
  //   "/images/partner/ep.png",
  //   "/images/partner/ftech.svg",
  //   "/images/partner/garena-logo.png",
  //   "/images/partner/Moonton-logo.png",
  //   "/images/partner/Nexon_Logo.png",
  //   "/images/partner/Riot-games-Logo.png",
  //   "/images/partner/Smilegate_logo-full.png",
  //   "/images/partner/tencent-games-png.png",
  //   "/images/partner/Tiktok.png",
  //   "/images/partner/VNG_GAMES_logo-white.png",
  //   "/images/partner/VTC_Online_logo.png",
  //   "/images/partner/tt.png",
  //   "/images/partner/vtvcad.png",
  //   "/images/partner/vtvlive.png",
  //   "/images/partner/vtv.png",
  // ];
  const images = [
    {
      link: "",
      image: "/images/partner/iesf.png",
    },
    {
      link: "",
      image: "/images/partner/gef3.png",
    },
    {
      link: "",
      image: "/images/partner/aesf.png",
    },
    {
      link: "",
      image: "/images/partner/SEAEF.jpg",
    },
    {
      link: "",
      image: "/images/partner/ep.png",
    },
    {
      link: "https://ftech.ai/",
      image: "/images/partner/ftech.svg",
    },
    {
      link: "",
      image: "/images/partner/garena-logo.png",
    },
    {
      link: "",
      image: "/images/partner/Moonton-logo.png",
    },
    {
      link: "",
      image: "/images/partner/Nexon_Logo.png",
    },
    {
      link: "",
      image: "/images/partner/Riot-games-Logo.png",
    },
    {
      link: "",
      image: "/images/partner/Smilegate_logo-full.png",
    },
    {
      link: "",
      image: "/images/partner/tencent-games-png.png",
    },
    {
      link: "",
      image: "/images/partner/Tiktok.png",
    },
    {
      link: "",
      image: "/images/partner/VNG_GAMES_logo-white.png",
    },
    {
      link: "",
      image: "/images/partner/VTC_Online_logo.png",
    },
    {
      link: "",
      image: "/images/partner/tt.png",
    },
    {
      link: "",
      image: "/images/partner/vtvcad.png",
    },
    {
      link: "",
      image: "/images/partner/vtvlive.png",
    },
    {
      link: "",
      image: "/images/partner/vtv.png",
    },
  ];
  return (
    <div className="col-12 col-md-6 col-lg-4">
      <div className="footer_main_title py-3">
        <Translate id="footer-about" />
      </div>
      <div
        className="footer_sub_about pb-3"
        dangerouslySetInnerHTML={{ __html: `${footer?.about}` }}
      ></div>
      <div className="footer_main_title py-3">
        <Translate id="footer-partner" />
      </div>
      <div className="footer_mediya_icon">
        {images.map((item) => (
          <div className="partner-icon text-center d-inline-block">
            <div className="">
              {item?.link ? (
                <a href={item?.link} target="_blank" rel="noreferrer">
                  <div className="fh5co_verticle_middle">
                    <img
                      width="88px"
                      src={item.image}
                      alt="partner"
                      loading="lazy"
                    />
                  </div>
                </a>
              ) : (
                <div className="fh5co_verticle_middle">
                  <img
                    width="88px"
                    src={item.image}
                    alt="partner"
                    loading="lazy"
                  />
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default memo(About);
