import Nav from "./Nav";
import Header from "components/Layout/Header";
import Footer from "components/Layout/Footer";

const Layout = ({ children }) => (
  <>
    <Header />
    <Nav />
    {children}
    <Footer />
  </>
);

export default Layout;
