import { getStrapiURL } from "lib/api";
import { useGlobalContext } from "pages/_app";
import { memo } from "react";
import About from "./About";
import Category from "./Category";
import MostViewedPost from "./MostViewedPost";

const Footer = () => {
  const { logoFooter } = useGlobalContext();
  const { footer } = useGlobalContext();
  return (
    <div className="container-fluid fh5co_footer_bg pb-3">
      <div className="container animate-box">
        <div className="row justify-content-center">
          <div className="col-12 spdp_right py-5">
            <img
              src={getStrapiURL(logoFooter?.url)}
              alt="img"
              className="footer_logo"
              loading="lazy"
            />
          </div>
          <div className="clearfix" />
          {footer && <About footer={footer} />}
          {footer && <Category footer={footer} />}
          {footer && <MostViewedPost footer={footer} />}
        </div>
      </div>
    </div>
  );
};

export default memo(Footer);
