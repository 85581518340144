import { memo } from "react";
import { useGlobalContext } from "pages/_app";
import { getStrapiURL } from "lib/api";
import LanguageSwitcher from "components/Shared/LanguageSwitcher";
import Link from "next/link";

const Header = () => {
  const { logoHeader } = useGlobalContext();

  return (
    <>
      <div className="container-fluid d-none d-sm-none d-md-block">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md-3 fh5co_padding_menu">
              <Link href="/">
                <a>
                  <img
                    src={getStrapiURL(logoHeader?.url)}
                    alt="img"
                    className="fh5co_logo_width logowidth"
                    style={{ display: "inline-block" }}
                  />
                </a>
              </Link>
            </div>
            <div className="col-12 col-md-7 fh5co_padding_menu">
              <div className="h5 fw-bold text-primary">
                <b>HỘI THỂ THAO ĐIỆN TỬ GIẢI TRÍ VIỆT NAM</b>
              </div>
              <div className="h6 text-secondary">
                <i>VIETNAM RECREATIONAL AND ELECTRONIC SPORT ASSOCIATION</i>
              </div>
            </div>
            <div className="col-12 col-md-2 align-self-center fh5co_mediya_right">
              <div className="d-inline-block text-center dd_position_relative ">
                <LanguageSwitcher />
              </div>
              <div className="clearfix" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(Header);
