export const formatTime = (time, locale) => {
  var options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  return new Date(time).toLocaleDateString(locale || "vi", options);
};

export const validEmail = (email) => {
  // eslint-disable-next-line prettier/prettier
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(String(email).toLowerCase());
};
export function validPhone(phone) {
  const regex = /^\d{1,12}$/;
  return regex.test(String(phone).toLowerCase());
  // VN
  // const regex = /((09|03|07|08|05)+([0-9]{8})\b)/g;
  return regex.test(String(phone).toLowerCase());
}

export const isEmpty = (obj) => {
  if (Array.isArray(obj)) {
    return obj.length > 0;
  }
  return Object.keys(obj).length === 0;
};

export const toSlug = (str = "") => {
  try {
    str = String(str);
    str = str.toLowerCase();
    str = str.replace(/(à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ)/g, "a");
    str = str.replace(/(è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ)/g, "e");
    str = str.replace(/(ì|í|ị|ỉ|ĩ)/g, "i");
    str = str.replace(/(ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ)/g, "o");
    str = str.replace(/(ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ)/g, "u");
    str = str.replace(/(ỳ|ý|ỵ|ỷ|ỹ)/g, "y");
    str = str.replace(/(đ)/g, "d");
    str = str.replace(/([^0-9a-z-\s])/g, "");
    str = str.replace(/(\s+)/g, "-");
    str = str.replace(/^-+/g, "");
    str = str.replace(/-+$/g, "");
    str = str.replace(/-+/g, "-");
    return str;
  } catch (error) {}
};
