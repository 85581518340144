import Translate from "locale/Translate";
export const navConfig = [
  {
    title: <Translate id="nav-home-page" />,
    link: "/",
  },
  {
    title: <Translate id="nav-introduction" />,
    children: [
      {
        title: <Translate id="nav-introduction-1" />,
        link: "/page/su-menh-tam-nhin",
      },
      {
        title: <Translate id="nav-introduction-2" />,
        link: "/page/ban-chap-hanh",
      },
      {
        title: <Translate id="nav-introduction-3" />,
        link: "/page/ban-kiem-tra",
      },
      {
        title: <Translate id="nav-introduction-4" />,
        link: "/page/hoi-vien-viresa",
      },
      // {
      //   title: <Translate id="nav-introduction-5" />,
      //   link: "/page/hoi-vien-tieu-bieu",
      // },
    ],
  },
  {
    title: <Translate id="nav-activity" />,
    link: "/category/giai-dau",
  },
  {
    title: <Translate id="nav-player" />,
    link: "/player",
  },
  // {
  //   title: <Translate id="nav-ranking" />,
  //   link: "/page/bang-xep-hang",
  // },
  {
    title: <Translate id="nav-library" />,
    link: "/library",
    children: [
      {
        title: <Translate id="nav-library-2" />,
        link: "/category/thu-vien-anh",
      },
      {
        title: <Translate id="nav-library-1-1" />,
        link: "/page/quyet-dinh-thanh-lap-viresa",
      },
      {
        title: <Translate id="nav-library-1-2" />,
        link: "/page/dieu-le",
      },
      {
        title: <Translate id="nav-library-1-3" />,
        link: "/page/van-ban-phap-ly-khac",
      },
    ],
  },
  {
    title: <Translate id="nav-news" />,
    link: "/news",
    children: [
      {
        title: <Translate id="nav-news-1" />,
        link: "/category/tin-trong-nuoc",
      },
      {
        title: <Translate id="nav-news-2" />,
        link: "/category/hop-tac-quoc-te",
      },
      {
        title: <Translate id="nav-news-3" />,
        link: "/category/su-kien-giai-dau",
      },
      {
        title: <Translate id="nav-news-4" />,
        link: "/category/goc-nhin-esports",
      },
    ],
    linkChildren: ["/category/home"],
  },
  {
    title: <Translate id="nav-contact" />,
    link: "/contact",
  },
  {
    title: <Translate id="nav-member" />,
    link: "/register/organization",
    children: [
      {
        title: <Translate id="nav-member-3" />,
        link: "/register/individual",
      },
      {
        title: <Translate id="nav-member-4" />,
        link: "/register/organization",
      },
    ],
  },
];
