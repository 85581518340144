import React from "react";
import { navConfig } from "./navConfig";
import { useRouter } from "next/router";
import Link from "next/link";
import { getStrapiURL } from "lib/api";
import { useGlobalContext } from "pages/_app";
import LanguageSwitcher from "components/Shared/LanguageSwitcher";

const Nav = () => {
  const router = useRouter();
  const slug = router.query.slug;
  const currentPath = !slug ? router.route : router.asPath;
  const { logoHeader } = useGlobalContext();
  // const [open, setOpen] = useState({});
  return (
    <div className="container-fluid bg-faded fh5co_padd_mediya padding_786">
      <div className="container padding_786">
        <nav className="navbar navbar-toggleable-md navbar-light ">
          <button
            className="navbar-toggler navbar-toggler-right mt-3"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="fa fa-bars"></span>
          </button>
          <Link href="/">
            <a className="navbar-brand">
              <img
                src={getStrapiURL(logoHeader?.url)}
                alt="img"
                className="mobile_logo_width"
                loading="lazy"
              />
            </a>
          </Link>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto">
              {navConfig.map((n, index) => {
                const Tag = ({ children }) =>
                  n.children ? (
                    <>{children}</>
                  ) : (
                    <Link href={n.link}>{children}</Link>
                  );
                return (
                  <li
                    key={index}
                    className={`nav-item ${
                      currentPath === n.link ||
                      n?.linkChildren?.find((l) => currentPath.includes(l)) ||
                      (n.children &&
                        n.children.find((e) => e.link === currentPath))
                        ? " active"
                        : ""
                    }${n.children ? " dropdown" : ""}`}
                  >
                    <Tag>
                      <p
                        className={`nav-link ${
                          n.children ? " dropdown-toggle" : ""
                        }`}
                        href={"#"}
                        id={`dropdownMenuButton${index}`}
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {n.title}
                      </p>
                    </Tag>
                    {n.children && (
                      <div
                        className="dropdown-menu"
                        aria-labelledby={`dropdownMenuLink_${index}`}
                      >
                        {n.children.map((c, i) => {
                          const ChildrenTag = ({ children }) =>
                            c.children ? (
                              <>{children}</>
                            ) : (
                              <Link href={c.link}>{children}</Link>
                            );
                          return (
                            <ChildrenTag key={i}>
                              <p
                                className={`dropdown-item ${
                                  currentPath === c.link ? " active" : ""
                                }`}
                              >
                                {c.title}
                              </p>
                            </ChildrenTag>
                          );
                        })}
                      </div>
                    )}
                  </li>
                );
              })}
            </ul>
            <LanguageSwitcher
              className={"d-block d-md-none"}
              style={{ marginTop: 12, maxWidth: "160px" }}
            />
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Nav;
