import { formatTime } from "lib/utils";
import Translate, { useLanguageContext } from "locale/Translate";
import Link from "next/link";

const { memo } = require("react/cjs/react.production.min");

const MostViewedPost = ({ footer }) => {
  const { locale } = useLanguageContext();
  const { mostViewArticles } = footer;
  return (
    <div className="col-12 col-md-4 col-lg-3 position_footer_relative">
      <div className="footer_main_title py-3">
        <Translate id="footer-most-view" />
      </div>
      {mostViewArticles?.map((m, i) => {
        return (
          <div key={i}>
            <div className="footer_makes_sub_font">
              {formatTime(m?.created_at || m?.published_at, locale)}
            </div>
            <Link href={`/${m?.slug}`}>
              <a className="footer_post pb-4">{m?.title}</a>
            </Link>
          </div>
        );
      })}
      <div className="footer_position_absolute">
        <img
          src="/images/footer_sub_tipik.png"
          alt="img"
          className="width_footer_sub_img"
          loading="lazy"
        />
      </div>
    </div>
  );
};
export default memo(MostViewedPost);
