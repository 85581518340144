import { useLanguageContext } from "locale/Translate";
import { useRouter } from "next/router";
import { useCookies } from "react-cookie";
import { memo } from "react";

const LanguageSwitcher = ({ style, className }) => {
  const { locale, locales, messages } = useLanguageContext();
  const [, setCookie] = useCookies(["NEXT_LOCALE"]);
  const router = useRouter();
  const onChangeLanguageSelect = (event) => {
    setCookie("NEXT_LOCALE", event.target.value);
    router.push(router.asPath, router.asPath, {
      locale: event.target.value,
    });
  };

  return (
    <>
      <select
        style={style}
        onChange={onChangeLanguageSelect}
        defaultValue={locale}
        className={"form-control fh5co_text_select_option " + className}
      >
        {locales.map((l) => (
          <option key={l} value={l}>
            {messages[l] || l}
          </option>
        ))}
      </select>
    </>
  );
};

export default memo(LanguageSwitcher);
