import Translate from "locale/Translate";
import Link from "next/link";
import { memo } from "react";

const Category = ({ footer }) => {
  const { categories } = footer;
  return (
    <div className="col-12 col-md-2 col-lg-2">
      <div className="footer_main_title py-3">
        <Translate id="footer-category" />
      </div>
      <ul className="footer_menu">
        {categories &&
          categories?.map((c, index) => {
            return (
              <li key={index}>
                <Link href={`/category/${c?.slug}`}>
                  <a>
                    <i className="fa fa-angle-right" />
                    &nbsp;{c?.name}
                  </a>
                </Link>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default memo(Category);
